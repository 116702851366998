<template>
  <div class="common-bg">
    <van-nav-bar title="常见问题" left-arrow @click-left="onClickLeft" />
    <div class="van-padded-15">
      <div class="van-content" v-html="data.content"></div>
    </div>
  </div>
</template>

<script>
import { getDataByKey } from '@/utils/api.js'
export default {
  data() {
    return {
      data: ''
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      getDataByKey('COMMONPROBLEM').then((res) => {
        this.data = res.data
      })
    },
    onClickLeft() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped></style>
